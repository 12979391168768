* {
	/* text-decoration: none !important; */
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.gradient-button {
	border-radius: 100rem;
	padding: 1rem;
	font-family: inherit;
	font-size: 1rem;
	padding: 0.5rem 3rem;
	box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
	border: solid 3px transparent;
	background-image: linear-gradient(
			rgba(178, 132, 132, 0),
			rgba(255, 255, 255, 0)
		),
		linear-gradient(101deg, #8edfda, #4f75ff);
	background-origin: border-box;
	background-clip: content-box, border-box, text;
	box-shadow: 2px 1000px 1px #fff inset, 4px 5px 2px rgb(239, 239, 239);
}

.gradient-button-text {
	font-size: 0.9rem;
	font-weight: 500;
	font-family: "Ulagadi Sans";
	background: -webkit-linear-gradient(0deg, #8edfda, #4f75ff);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.gradient-button:hover {
	box-shadow: none;
	color: white;
}

.gradient-button:hover .gradient-button-text {
	background: -webkit-linear-gradient(0deg, #ffffff, #ffffff);
	background-clip: text;
	-webkit-background-clip: text;
}

.css-1gjatod-MuiDrawer-docked,
.css-1gjatod-MuiDrawer-docked .MuiDrawer-paper {
	/* width: 20% !important; */
	/* border-radius: 0 !important; */
}
.css-m43eof-MuiList-root {
	/* padding-left: 0 !important; */
}
.css-1tqzmxl-MuiTypography-root {
	/* font-size: 14px !important; */
	/* line-height: 1 !important; */
}
.css-1rnl9w6-MuiPaper-root {
	/* margin-bottom: 10px !important; */
}
.css-ona58w-MuiAvatar-root {
	/* height: auto !important; */
	/* margin: 0 auto !important; */
	/* margin-bottom: 5px !important; */
	/* width: 135px !important; */
}
.css-wzk2sm-MuiTypography-root {
	/* font-size: 14px !important; */
}
.general-container-mod {
	/* padding: 50px 50px 0px 50px !important; */
}
.css-nhsc3s-MuiList-root {
	/* padding: 0 !important; */
}
