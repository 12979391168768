/* * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
}

body {
	font-size: 1.8rem;
	min-height: 100vh;
	max-height: fit-content;
} */

.app {
	display: grid;
	grid-template-columns: 15% 85%;
	min-height: 100vh;
}

.general-container {
	display: grid;
	/* grid-template-rows: 0.1fr 1fr; */
	width: 100%;
	min-height: 100%;
	max-height: fit-content;
	/* background: linear-gradient(90deg, rgba(240,255,254,1) 0%, rgba(255,255,255,1) 100%); */
	padding: 100px 100px 0px 100px;
}

.general-container-mod {
	display: flex;
	overflow-y: auto;
	flex-direction: column;
	flex-wrap: nowrap;
	/* grid-template-rows: 0.1fr 1fr; */
	/* background: linear-gradient(90deg, rgba(240,255,254,1) 0%, rgba(255,255,255,1) 100%); */
	padding: 75px 75px 0px 75px;
	align-items: stretch;
	justify-content: flex-start;
	width: 100%;
}

.general-header {
	width: 90%;
	margin: 0 auto;
	display: flex;
	align-items: center;
}
.router-background {
	background-color: whitesmoke;
}

.chatForm {
	width: 100%;
	height: 100%;
}

.grammarlyInput {
	width: 100%;
}

grammarly-editor-plugin,
grammarly-button {
	color-scheme: dark;
}

::-webkit-scrollbar {
	width: 4px;
	height: 5px;
}
::-webkit-scrollbar-track {
	background: linear-gradient(#2a1285, #086d73);
	border-radius: 5px;
}
::-webkit-scrollbar-thumb {
	background: #0f4b58;
	border-radius: 5px;
}
.descriptionBox{
	border: 1px solid #285d6991;
	border-radius: 7px;
	background-color: #dfedf0;
	padding: 6px 8px;
	min-height: 100px;
	font-size: 1.4rem;
	max-width: 300px;
}
.reqInfoDescription{
	border: 1px solid #285d6991;
	border-radius: 7px;
	background-color: #dfedf0;
	padding: 6px 8px;
	min-height: 100px;
	font-size: 1.4rem;
	max-width: 400px;
}