.questionnaire-container {
	/* display: flex;
    box-sizing: border-box; */
}

.questionnaire-title {
	margin-left: 3rem;
	margin-top: 3rem;
	margin-bottom: 1rem;
}

.question-text {
	font-weight: 400;
	margin-left: 3rem;
	margin-bottom: 0.5rem;
}

.input-box {
	display: flex;
	background-color: rgb(215, 215, 215);
	border-radius: 5px;
	margin: 0.5rem 2rem 0.5rem 2rem;
	/* padding: 0.8rem 0.2rem */
}

.questionnaire-input {
	display: flex;
	flex: 1;
	font-size: inherit;
	padding: 0.8rem 0.2rem 0.8rem 0.5rem;
	border: none;
	background-color: transparent;
}

.questionnaire-input {
	flex: 1;
	font-size: inherit;
	padding: 0.8rem 0.2rem 0.8rem 0.5rem;
	border: none;
	background-color: transparent;
}
.questionnaire-container {
	display: flex;
	flex-direction: column;
}

.question-text {
	color: #1976d2;
	font-weight: 300;
	margin-bottom: 1.5rem;
}

.input-box {
	display: flex;
	background-color: #f8f7f7;
	border-radius: 5px;
	margin: 0.5rem 3rem 2.5rem 3rem;
	box-shadow: 2px 2px 2px #f0f0f0;
}

.questionnaire-input {
	flex: 1;
	font-size: inherit;
	padding: 1.2rem 0.2rem 1.2rem 0.85rem;
	border: none;
	background-color: transparent;
}

.questionnaire-input:focus {
	background-color: #fafafa;
	outline: none;
}

.questionnaire-input::placeholder {
	color: #797979;
}

.questionnaire-button-container {
	align-self: center;
	display: flex;
	justify-content: flex-end;
	margin-top: 5rem;
	margin-bottom: 5rem;
	width: 20rem;
}

.questionnaire-button {
	flex: 1;
	background-image: linear-gradient(
		to right,
		#759ff7 0%,
		#90caea 51%,
		#8eded9 100%
	);
	background-color: transparent;
	color: white;
	font-size: 1.5rem;
	font-family: "Ulagadi Sans";
	font-weight: 600;
	padding: 1.2rem 2rem;
	border-radius: 15px;
	border: none;
}

.questionnaire-button:hover {
	background-image: linear-gradient(
		to right,
		#6489d7 0%,
		#86bdda 51%,
		#87c7c3 100%
	);
	background-color: transparent;
}

.add-option-button-container {
	/* background-color: red; */
	display: flex;
	flex-direction: row;
	height: 2.3rem;
	margin-left: 3rem;
	margin-right: 3rem;
	justify-content: center;
}

.add-option-button {
	align-items: center;
	background-image: linear-gradient(
		to right,
		#759ff7 0%,
		#3dc094 51%,
		#44d661 100%
	);
	background-color: transparent;
	border: none;
	border-radius: 20px;
	margin-left: 43%;
	color: white;
	display: flex;
	font-size: 1rem;
	justify-content: center;
	width: 15rem;
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	padding-right: 1.5rem;
	padding-left: 0.2rem;
}

.add-option-button:hover {
	background-image: linear-gradient(
		to right,
		#678ddf 0%,
		#33a57f 51%,
		#3bbf56 100%
	);
}

.add-option-button-single {
	align-items: center;
	background-image: linear-gradient(
		to right,
		#759ff7 0%,
		#3dc094 51%,
		#44d661 100%
	);
	background-color: transparent;
	border: none;
	border-radius: 20px;
	color: white;
	display: flex;
	font-size: 1rem;
	justify-content: center;
	width: 15rem;
}

.add-option-button-single:hover {
	background-image: linear-gradient(
		to right,
		#678ddf 0%,
		#33a57f 51%,
		#3bbf56 100%
	);
}

.remove-option-button {
	align-items: center;
	background-image: linear-gradient(
		to right,
		#ffc825 0%,
		#ff673d 51%,
		#ff4d4d 100%
	);
	background-color: transparent;
	border: none;
	border-radius: 20px;
	margin-left: 30%;
	color: white;
	display: flex;
	font-size: 1rem;
	justify-content: center;
	width: 15rem;
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	padding-left: 0.2rem;
	padding-right: 0.5rem;
}

.remove-option-button:hover {
	background-image: linear-gradient(
		to right,
		#ecba22 0%,
		#ea633e 51%,
		#f04f4f 100%
	);
}

.questionnaire-option-header {
	/* color: #797979; */
	margin-left: 3.8rem;
	margin-bottom: 1rem;
	font-family: inherit;
	font-size: 1rem;
	font-weight: 400;
}

.dynamic-div-container {
	display: flex;

	/* width: 2rem; */
	flex-direction: column;
}
